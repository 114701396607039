import { APP_VERSION } from '@confectionary-env/version';

export const environment = {
  production: true,
  version: APP_VERSION,
  sentryUrl: 'https://7b61e9360ade4fc8a408527885e20ec4@sentry.happycake.co/8',
  keycloakUrl: 'https://auth.happyapps.pro/auth',
  keycloakRealm: 'happy',
  keycloakClientId: 'cf-frontend'
};
